import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/templates/blog-post.js"

export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = DefaultLayout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={props}
             components={components}>

<MDXTag name="p" components={components}>{`After a year of building and maintaining
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://github.com/nicolodavis/boardgame.io/"}}>{`boardgame.io`}</MDXTag>{`
on the weekends, I’ve learned some things that might be
useful to some of you thinking about starting open source
projects on the side. Here are some thoughts:`}</MDXTag>
<MDXTag name="h3" components={components}>{`1. You don’t have to be an expert.`}</MDXTag>
<MDXTag name="p" components={components}>{`This is something that often holds people back. The point of
an open source project isn’t to show off your abilities to
the world. The point is to get contributions from the community
in the form of ideas, bug reports and code. These will shape
the project, and depending on how you manage it, can take an
early work-in-progress prototype to something much larger.`}</MDXTag>
<MDXTag name="p" components={components}>{`When I first started `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://github.com/nicolodavis/boardgame.io/"}}>{`boardgame.io`}</MDXTag>{`,
I was quite new to a lot of web technology like React, Redux, socket.io
and NPM (having mostly written C++ code prior to that).
However, the project was a great learning experience for levelling up
on frontend technology and I’m no longer afraid of Webpack configs (OK, maybe just a little bit).`}</MDXTag>
<MDXTag name="h3" components={components}>{`2. Document everything.`}</MDXTag>
<MDXTag name="p" components={components}>{`Spend as much time on documentation as you are on code. I
can’t emphasize this enough! If you want your project to
be used by a lot of people, make sure that the docs cover
everything from how to get started (tutorials) to guides
for more advanced users.`}</MDXTag>
<MDXTag name="p" components={components}>{`There are many excellent Markdown
based documentation starters like
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.gitbook.com/"}}>{`GitBook`}</MDXTag>{`, `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://squidfunk.github.io/mkdocs-material/"}}>{`Material for MkDocs`}</MDXTag>{` and `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://docsify.js.org/"}}>{`docsify.js`}</MDXTag>{` that you can use for this.`}</MDXTag>
<MDXTag name="p" components={components}>{`Make sure that you also have a separate section for contributors. You should highlight your expectations here and also include
pointers to how they can find things to contribute to.`}</MDXTag>
<MDXTag name="h3" components={components}>{`3. Test everything.`}</MDXTag>
<MDXTag name="p" components={components}>{`Strive for 100% test coverage wherever possible. By this I mean
that every line of code should be executed by at least one test
and every conditional should be tested on both the `}<MDXTag name="em" components={components} parentName="p">{`true`}</MDXTag>{`
and `}<MDXTag name="em" components={components} parentName="p">{`false`}</MDXTag>{` branches.
Not only is it therapeutic to see that perfect test
score, but it is particularly important for open source projects that are
meant to receive contributions from the internet. If you have
good test coverage, you can confidently receive contributions
without worrying about code breakages.`}</MDXTag>
<MDXTag name="p" components={components}>{`If you are coding in JavaScript, `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://jestjs.io/"}}>{`Jest`}</MDXTag>{`
can collect coverage statistics (see my other `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://nicolodavis.com/blog/jest-function-coverage/"}}>{`post`}</MDXTag>{` about this). You
can have your tests run automatically whenever you push your
code to GitHub by connecting a Continuous Integration service
like `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://travis-ci.org/"}}>{`Travis`}</MDXTag>{`, `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://circleci.com/"}}>{`CircleCI`}</MDXTag>{` or `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://semaphoreci.com/"}}>{`Semaphore`}</MDXTag>{`. You should
also consider including a `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://shields.io/"}}>{`badge`}</MDXTag>{` on
your documentation page to display your test coverage and
current build status. This will generate confidence in potential
contributors that you maintain a high quality codebase that
is easy to modify.`}</MDXTag>
<MDXTag name="h3" components={components}>{`4. Get people involved.`}</MDXTag>
<MDXTag name="p" components={components}>{`The key to scaling out progress is to get others involved.
This is why it’s a good idea to get something out even if
it’s just an early prototype that you aren’t particularly
proud of yet. As long as you are able to communicate your
idea clearly, people will get excited about it and jump on
board.`}</MDXTag>
<MDXTag name="p" components={components}>{`Contributions will come in the form of code fixes, bug reports,
documentation typo fixes, and feature requests. All of these are
extremely valuable, and you should cultivate a friendly
community that’s open to feedback from anyone, regardless
of how experienced they are.`}</MDXTag>
<MDXTag name="p" components={components}>{`At this point you will start to realize that more of your time
is spent guiding and directing. These are very good skills
to develop, and an open source project is a great place to
practise them.`}</MDXTag>
<MDXTag name="h3" components={components}>{`5. Productivity tips.`}</MDXTag>
<MDXTag name="p" components={components}>{`There are many projects on GitHub that have a few initial
commits and are then abandoned (probably because the author
got busy or bored). The key to success is to to stay productive
on your project for months and years.`}</MDXTag>
<MDXTag name="p" components={components}>{`One strategy that I’ve found helpful is to pace yourself. This
sounds counter-intuitive, but resist the urge to spend a lot
of time on your side project. Document your ideas for
features on little notes and only start coding when you have a
clear idea about what you want to build. It’s also OK to
leave work unfinished that you can get back to the following
week.`}</MDXTag>
<MDXTag name="p" components={components}>{`Also, don’t feel obliged to implement every single feature
request that comes in. You are serving the community by
volunteering your time on this project. While you must strive
to serve your users well, they are not entitled to your
time. Saying no to certain requests will become key to
staying productive on your current todo list.`}</MDXTag>
<MDXTag name="hr" components={components}></MDXTag>
<MDXTag name="p" components={components}>{`An open source project is a great way to improve your skill
as a programmer. It will also help you pick up other skills that
will help you in your professional career. As project maintainer, you will get to practise
leadership, development and marketing in a low-risk environment. So, get your ideas out there and build something cool with the
community!`}</MDXTag>
           </MDXTag>
  }
}

export const _frontmatter = {};

  